import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import BackgroundSlider from 'gatsby-image-background-slider';

/*
		<link href="https://fonts.googleapis.com/css?family=Julee&display=swap" rel="stylesheet" />
		<link href="https://fonts.googleapis.com/css?family=Eagle+Lake&display=swap" rel="stylesheet" />
		<link href="https://fonts.googleapis.com/css?family=Quintessential&display=swap" rel="stylesheet" />
		<link href="https://fonts.googleapis.com/css?family=Beth+Ellen&display=swap" rel="stylesheet"/>
		<link href="https://fonts.googleapis.com/css?family=Charmonman&display=swap" rel="stylesheet" />
		*/
export default () => (
	<div style={{ color: `purple` }}>
		<link href="https://fonts.googleapis.com/css?family=Italianno&display=swap" rel="stylesheet"/>
		<link href="https://fonts.googleapis.com/css?family=Cinzel+Decorative&display=swap" rel="stylesheet"/>
		<link href="https://fonts.googleapis.com/css?family=Jim+Nightshade&display=swap" rel="stylesheet" />
	
		<Splash />
		<BackgroundSlider
			query={useStaticQuery(graphql`
				query {
					backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
						nodes {
							relativePath
								childImageSharp {
									fluid (maxWidth: 4000, quality: 100){
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			`)}
		/>
	</div>
);

function MiddleSection(props) {
	return (
		<div className="container">
			<div className="middle">
				{props.children}
			</div>
		</div>
	);
}


function Splash(props) {
	return (
		<MiddleSection>
			<h1 className="header blur-in">Wedding of Réka and Isaac</h1>
			<h1 className="old_hungarian blur-out">𐲮‍𐲮𐳉𐳇𐳇𐳐𐳙𐳍 𐳛𐳌 𐲢𐳉𐳓𐳀 𐳀𐳙𐳇 𐲐𐳤𐳀𐳀𐳄</h1>

			<p className="subtitle">Transylvania, Romania</p>
			<p className="subtitle">October 2nd, 2021</p>
			<br />

			<p className='story'>
				<Hidden link="Our Story" className="story-link">
					<p>In short, we met in Washington DC, engaged in Budapest on New Year's eve (2018/19), legally married in Washington DC on October 12th, 2019, and finally will be wed before friends and family in Transylvania on October 2nd, 2021.
					</p>
				</Hidden>
			</p>

			<p className='details'>
				<Hidden link="Details" className="details-link">
					<p><span className="field">Venue Address:</span> <br />
						Paradisul Transilvaniei, Strada Horia Nr43, Blaj 515400, Romania
					</p>
					<p><span className="field">Local Airports:</span> <br />
						CLJ, SBZ, TGM
					</p>
					<p>
						<a href="https://www.zola.com/registry/isaacandreka">Wedding Registry</a>
					</p>
					<p>For inquiries, travel assistance, and to RSVP, write to <b>lenoxisaac@gmail.com</b></p>
				</Hidden>
			</p>

			<br />
			<p>
				<span className="old_hungarian">
					𐲮‍𐲮𐳉𐳇𐳇𐳐𐳙𐳍 𐳛𐳌 𐲢𐳉𐳓𐳀 𐳀𐳙𐳇 𐲐𐳤𐳀𐳀𐳄  
				</span>
				<br />
				<Hidden link="What is this?" className="what-dis">
					The above is Old Hungarian Runic which Réka learned as a child and is still taught in some Transylvanian schools. It reads right to left and means "Wedding of Reka and Isaac" (a rough translation).
					<br />
				</Hidden>
			</p>

		</MiddleSection>
	);
}

function Hidden(props) {
	const [hidden, setHidden] = useState(true);

	// Instead of doing ternaries, I like this for 
	// 	readability.
	let more_link = '';
	let less_link = '';
	let children = '';

	if (hidden) {
		more_link = 
			<a onClick={() => setHidden(false)} className={props.className}>
				{props.link}
			</a>
	} else {
		children = props.children;
		less_link = <a onClick={() => setHidden(true)} className='hide-link'>{props.less_link ? props.less_link : 'hide'}</a>
	}
	
	return (
		<>
			{more_link}
			{children}
			{less_link}
		</>
	);
}

